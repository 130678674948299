export const albums_data = {
   "random": {
      "name": "Random",
      "photos": [
         {
            "faces": [],
            "height": 3939,
            "name": "20230508-WTL06387-1.jpg",
            "slug": "20230508-wtl06387-1-jpg",
            "src": "/static/_gallery/albums/random/1600x1066_20230508-wtl06387-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/random/1024x682_20230508-wtl06387-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/random/1600x1066_20230508-wtl06387-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/random/500x333_20230508-wtl06387-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/random/800x533_20230508-wtl06387-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/random/500x333_20230508-wtl06387-1-jpg.jpg",
            "width": 5909
         },
         {
            "faces": [],
            "height": 5694,
            "name": "20230512-WTL06503-1.jpg",
            "slug": "20230512-wtl06503-1-jpg",
            "src": "/static/_gallery/albums/random/1600x2400_20230512-wtl06503-1-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/random/1024x1536_20230512-wtl06503-1-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/random/1600x2400_20230512-wtl06503-1-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/random/500x750_20230512-wtl06503-1-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/random/800x1200_20230512-wtl06503-1-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/random/500x750_20230512-wtl06503-1-jpg.jpg",
            "width": 3796
         },
         {
            "faces": [],
            "height": 3199,
            "name": "20230512-WTL06505-3.jpg",
            "slug": "20230512-wtl06505-3-jpg",
            "src": "/static/_gallery/albums/random/1600x1066_20230512-wtl06505-3-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/random/1024x682_20230512-wtl06505-3-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/random/1600x1066_20230512-wtl06505-3-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/random/500x333_20230512-wtl06505-3-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/random/800x533_20230512-wtl06505-3-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/random/500x333_20230512-wtl06505-3-jpg.jpg",
            "width": 4798
         }
      ],
      "slug": "random",
      "src": "/static/_gallery/albums/random/500x333_20230508-wtl06387-1-jpg.jpg"
   }
};